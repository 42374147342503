import React from 'react'

export default function Pause() {
  return (
    <svg width="29" height="39" viewBox="0 0 29 39" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9.12281 0H0V39H9.12281V0Z" fill="white" />
      <path d="M28.8889 0H19.7661V39H28.8889V0Z" fill="white" />
    </svg>
  );
}
