import React from 'react';

export default function Waves({animated}:{animated:boolean}) {
  const animationClasses = animated ? 'svg-wave-animated' : '';
  return (
    <svg className={animationClasses} width="71" height="54" viewBox="0 0 71 54" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1.95737 30.4189C1.1158 30.4189 0.427246 29.7303 0.427246 28.8888V24.482C0.427246 23.6864 1.0699 23.0437 1.86556 23.0437H2.04917C2.84483 23.0437 3.48749 23.6864 3.48749 24.482V28.8888C3.48749 29.7303 2.81423 30.4189 1.95737 30.4189Z" fill="url(#wave-gradient-bg-0)" />
      <path d="M8.07773 43.1952C7.23616 43.1952 6.54761 42.5067 6.54761 41.6651V11.69C6.54761 10.8944 7.19026 10.2517 7.98592 10.2517H8.16953C8.96519 10.2517 9.60785 10.8944 9.60785 11.69V41.6651C9.60785 42.5067 8.9346 43.1952 8.07773 43.1952Z" fill="url(#wave-gradient-bg-1)" />
      <path d="M14.1983 49.683C13.3568 49.683 12.6682 48.9944 12.6682 48.1529V5.21767C12.6682 4.42201 13.3109 3.77936 14.1065 3.77936H14.2901C15.0858 3.77936 15.7285 4.42201 15.7285 5.21767V48.1529C15.7285 48.9944 15.0552 49.683 14.1983 49.683Z" fill="url(#wave-gradient-bg-2)" />
      <path d="M20.3187 43.1952C19.4771 43.1952 18.7886 42.5067 18.7886 41.6651V11.69C18.7886 10.8944 19.4312 10.2517 20.2269 10.2517H20.4105C21.2062 10.2517 21.8488 10.8944 21.8488 11.69V41.6651C21.8488 42.5067 21.1756 43.1952 20.3187 43.1952Z" fill="url(#wave-gradient-bg-3)" />
      <path d="M26.4393 39.8291C25.5977 39.8291 24.9092 39.1405 24.9092 38.299V15.0717C24.9092 14.2761 25.5518 13.6334 26.3475 13.6334H26.5311C27.3268 13.6334 27.9694 14.2761 27.9694 15.0717V38.299C27.9694 39.1405 27.2962 39.8291 26.4393 39.8291Z" fill="url(#wave-gradient-bg-4)" />
      <path d="M32.5597 44.2817C31.7181 44.2817 31.0295 43.5931 31.0295 42.7516V10.619C31.0295 9.82338 31.6722 9.18073 32.4679 9.18073H32.6515C33.4471 9.18073 34.0898 9.82338 34.0898 10.619V42.7516C34.0898 43.5931 33.4165 44.2817 32.5597 44.2817Z" fill="url(#wave-gradient-bg-5)" />
      <path d="M38.6803 38.6968C37.8387 38.6968 37.1501 38.0082 37.1501 37.1667V16.1887C37.1501 15.393 37.7928 14.7504 38.5885 14.7504H38.7721C39.5677 14.7504 40.2104 15.393 40.2104 16.1887V37.1667C40.2104 38.0082 39.5371 38.6968 38.6803 38.6968Z" fill="url(#wave-gradient-bg-6)" />
      <path d="M44.8006 53.4624C43.9591 53.4624 43.2705 52.7739 43.2705 51.9323V1.43832C43.2705 0.642654 43.9132 0 44.7088 0H44.8924C45.6881 0 46.3307 0.642654 46.3307 1.43832V51.9323C46.3307 52.7739 45.6575 53.4624 44.8006 53.4624Z" fill="url(#wave-gradient-bg-7)" />
      <path d="M50.9212 48.459C50.0797 48.459 49.3911 47.7704 49.3911 46.9289V6.42654C49.3911 5.63087 50.0338 4.98822 50.8294 4.98822H51.013C51.8087 4.98822 52.4514 5.63087 52.4514 6.42654V46.9289C52.4514 47.7704 51.7781 48.459 50.9212 48.459Z" fill="url(#wave-gradient-bg-8)" />
      <path d="M57.0416 45.4445C56.2 45.4445 55.5115 44.756 55.5115 43.9144V9.44082C55.5115 8.64516 56.1541 8.0025 56.9498 8.0025H57.1334C57.9291 8.0025 58.5717 8.64516 58.5717 9.44082V43.9144C58.5717 44.756 57.8985 45.4445 57.0416 45.4445Z" fill="url(#wave-gradient-bg-9)" />
      <path d="M63.1622 39.8291C62.3206 39.8291 61.6321 39.1405 61.6321 38.299V15.0717C61.6321 14.2761 62.2747 13.6334 63.0704 13.6334H63.254C64.0497 13.6334 64.6923 14.2761 64.6923 15.0717V38.299C64.6923 39.1405 64.0191 39.8291 63.1622 39.8291Z" fill="url(#wave-gradient-bg-10)" />
      <path d="M69.2826 47.2502C68.441 47.2502 67.7524 46.5617 67.7524 45.7201V7.65072C67.7524 6.85506 68.3951 6.2124 69.1908 6.2124H69.3744C70.17 6.2124 70.8127 6.85506 70.8127 7.65072V45.7201C70.8127 46.5617 70.1394 47.2502 69.2826 47.2502Z" fill="url(#wave-gradient-bg-11)" />
      <defs>
        <linearGradient id="wave-gradient-bg-0" x1="0.456624" y1="26.7259" x2="3.49514" y2="26.7259" gradientUnits="userSpaceOnUse">
          <stop offset="0.0169" stopColor="#9E247A" />
          <stop offset="1" stopColor="#ED2542" />
        </linearGradient>
        <linearGradient id="wave-gradient-bg-1" x1="6.57699" y1="26.7258" x2="9.61565" y2="26.7258" gradientUnits="userSpaceOnUse">
          <stop offset="0.0169" stopColor="#9E247A" />
          <stop offset="1" stopColor="#ED2542" />
        </linearGradient>
        <linearGradient id="wave-gradient-bg-2" x1="12.6976" y1="26.7258" x2="15.7363" y2="26.7258" gradientUnits="userSpaceOnUse">
          <stop offset="0.0169" stopColor="#9E247A" />
          <stop offset="1" stopColor="#ED2542" />
        </linearGradient>
        <linearGradient id="wave-gradient-bg-3" x1="18.818" y1="26.7258" x2="21.8566" y2="26.7258" gradientUnits="userSpaceOnUse">
          <stop offset="0.0169" stopColor="#9E247A" />
          <stop offset="1" stopColor="#ED2542" />
        </linearGradient>
        <linearGradient id="wave-gradient-bg-4" x1="24.9386" y1="26.7259" x2="27.9772" y2="26.7259" gradientUnits="userSpaceOnUse">
          <stop offset="0.0169" stopColor="#9E247A" />
          <stop offset="1" stopColor="#ED2542" />
        </linearGradient>
        <linearGradient id="wave-gradient-bg-5" x1="31.0589" y1="26.7259" x2="34.0976" y2="26.7259" gradientUnits="userSpaceOnUse">
          <stop offset="0.0169" stopColor="#9E247A" />
          <stop offset="1" stopColor="#ED2542" />
        </linearGradient>
        <linearGradient id="wave-gradient-bg-6" x1="37.1795" y1="26.7259" x2="40.218" y2="26.7259" gradientUnits="userSpaceOnUse">
          <stop offset="0.0169" stopColor="#9E247A" />
          <stop offset="1" stopColor="#ED2542" />
        </linearGradient>
        <linearGradient id="wave-gradient-bg-7" x1="43.2999" y1="26.7259" x2="46.3386" y2="26.7259" gradientUnits="userSpaceOnUse">
          <stop offset="0.0169" stopColor="#9E247A" />
          <stop offset="1" stopColor="#ED2542" />
        </linearGradient>
        <linearGradient id="wave-gradient-bg-8" x1="49.4205" y1="26.7259" x2="52.459" y2="26.7259" gradientUnits="userSpaceOnUse">
          <stop offset="0.0169" stopColor="#9E247A" />
          <stop offset="1" stopColor="#ED2542" />
        </linearGradient>
        <linearGradient id="wave-gradient-bg-9" x1="55.5409" y1="26.7258" x2="58.5795" y2="26.7258" gradientUnits="userSpaceOnUse">
          <stop offset="0.0169" stopColor="#9E247A" />
          <stop offset="1" stopColor="#ED2542" />
        </linearGradient>
        <linearGradient id="wave-gradient-bg-10" x1="61.6615" y1="26.7259" x2="64.7001" y2="26.7259" gradientUnits="userSpaceOnUse">
          <stop offset="0.0169" stopColor="#9E247A" />
          <stop offset="1" stopColor="#ED2542" />
        </linearGradient>
        <linearGradient id="wave-gradient-bg-11" x1="67.7818" y1="26.726" x2="70.8205" y2="26.726" gradientUnits="userSpaceOnUse">
          <stop offset="0.0169" stopColor="#9E247A" />
          <stop offset="1" stopColor="#ED2542" />
        </linearGradient>
      </defs>
    </svg>
  )
}
