import React from 'react';
import { KeyValueObject } from '../../types/shared';

export default function DiskIcon({ className }: KeyValueObject<string>) {
  return (
    <div className={className}>
      <svg width="99" height="99" viewBox="0 0 99 99" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M86.5569 18.1208C78.1757 8.22155 66.4404 2.18358 53.5127 1.11227C40.5926 0.0296 28.017 4.06501 18.1178 12.4462C8.21853 20.8275 2.18057 32.5627 1.10926 45.4866C0.0379425 58.4143 4.06199 70.9823 12.4432 80.8816C20.8244 90.7808 32.5597 96.8188 45.4836 97.8901C46.8464 98.0037 48.2054 98.0604 49.5568 98.0604C59.986 98.0604 70.067 94.7253 78.4709 88.4678V95.0774C78.4709 95.8042 79.0615 96.3948 79.7883 96.3948C80.5151 96.3948 81.1057 95.8042 81.1057 95.0774V73.7306H82.0256C83.7404 73.7306 85.1335 72.3376 85.1335 70.6265V61.2837C85.1335 59.5689 83.7404 58.1758 82.0256 58.1758H81.1057V56.2376C81.1057 55.5107 80.5151 54.9202 79.7883 54.9202C79.0615 54.9202 78.4709 55.5107 78.4709 56.2376V58.1758H77.5473C75.8362 58.1758 74.4431 59.5689 74.4431 61.2837V70.6265C74.4431 72.3376 75.8362 73.7306 77.5473 73.7306H78.4709V85.1252C69.2228 92.6509 57.586 96.2547 45.7031 95.2629C33.4796 94.2484 22.3803 88.536 14.4533 79.1743C6.52639 69.8126 2.71812 57.9259 3.73265 45.7024C4.74718 33.4788 10.4596 22.3795 19.8175 14.4564C29.1792 6.5294 41.0658 2.72491 53.2894 3.73566C65.513 4.75019 76.6122 10.4626 84.5392 19.8243C92.4661 29.1822 96.2744 41.0726 95.2599 53.2924C94.5179 62.2188 91.232 70.653 85.7543 77.6866C85.3076 78.262 85.4099 79.0872 85.9853 79.5377C86.5569 79.9844 87.3859 79.8784 87.8364 79.3068C93.6283 71.8719 97.1072 62.9531 97.8871 53.512C98.9659 40.5881 94.9381 28.0162 86.5569 18.1208ZM77.0854 70.6265V61.2837C77.0854 61.0263 77.2936 60.8143 77.5548 60.8143H82.0331C82.2906 60.8143 82.5025 61.0263 82.5025 61.2837V70.6265C82.5025 70.8839 82.2906 71.0959 82.0331 71.0959H77.5548C77.2974 71.0959 77.0854 70.8877 77.0854 70.6265Z" fill="white" />
        <path d="M48.5538 60.9549C48.8755 60.9814 49.1935 60.9965 49.5115 60.9965C55.4208 60.9965 60.4593 56.4463 60.9553 50.4538C61.4814 44.1395 56.7684 38.5747 50.4541 38.0485C44.1057 37.4996 38.5712 42.2353 38.0488 48.5534C37.5264 54.8677 42.2394 60.4325 48.5538 60.9549ZM40.676 48.7692C40.8729 46.4108 41.9745 44.2719 43.7802 42.7426C45.3966 41.376 47.3992 40.6454 49.4888 40.6454C49.7386 40.6454 49.9847 40.6567 50.2346 40.6757C55.1028 41.0807 58.7331 45.3698 58.3319 50.2342C58.135 52.5926 57.0334 54.7314 55.2277 56.2608C53.422 57.7902 51.1242 58.5208 48.7733 58.3277C43.9051 57.9264 40.2747 53.6374 40.676 48.7692Z" fill="white" />
        <path d="M28.1648 49.0531C28.2026 49.0569 28.2745 49.0569 28.2745 49.0569C28.9522 49.0569 29.5313 48.5345 29.5881 47.8493C30.5005 36.8674 40.1953 28.7171 51.1545 29.584C51.8851 29.6673 52.5173 29.1033 52.5778 28.3802C52.6384 27.6534 52.0971 27.0174 51.374 26.9568C45.3361 26.442 39.4949 28.3348 34.8879 32.2377C30.2771 36.1406 27.4606 41.6069 26.961 47.6298C26.9004 48.3604 27.4379 48.9964 28.1648 49.0531Z" fill="white" />
        <path d="M52.1991 16.9857C52.9297 17.0424 53.5619 16.5049 53.6224 15.7819C53.683 15.055 53.1417 14.419 52.4186 14.3585C33.0176 12.7648 15.9675 27.2142 14.3586 46.5925C14.2981 47.3194 14.8394 47.9553 15.5624 48.0159C15.6003 48.0197 15.6722 48.0197 15.6722 48.0197C16.3498 48.0197 16.929 47.4973 16.9858 46.8121C18.4697 28.8761 34.2669 15.4828 52.1991 16.9857Z" fill="white" />
      </svg>
    </div>
  )
}
