/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */
const React = require("react")
const Player = require('./src/components/Player').default;
// You can delete this file if you're not using it
exports.wrapPageElement = ({ element, props }) => {
  // props provide same data to Layout as Page element will get
  // including location, data, etc - you don't need to pass it
  return (
    <div>
      {element}
      <Player className='position-fixed bottom_0 w-100' />
    </div>
  )
}