import React from 'react';

export default function Play({ containerClassName, className, height=26, width=25 }: any) {
  return (
    <div className={containerClassName}>
      <svg className={className} width={width} height={height} viewBox="0 0 25 26" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0.0246582 0.93158L24.3283 13.0834L0.0246582 25.2352V0.93158Z" fill="#F2F2F2" />
      </svg>
    </div>
  );
}
