import React from 'react';
import { KeyValueObject } from '../../types/shared';

export default function VolumeIcon({className}:KeyValueObject<string>) {
  return (
    <div className={className}>
      <svg width="33" height="27" viewBox="0 0 33 27" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g opacity="0.2">
          <path opacity="0.2" d="M19.2183 26.1237H16.816L9.16541 20.0032H2.20338L0 17.7999V10.7307L2.20338 8.52734H9.16541L16.816 2.40686H19.2183L20.6566 3.84518V24.6854L19.2183 26.1237ZM17.6117 23.8285H18.2696L18.3614 23.7367V4.79384L18.2696 4.70204H17.6117L9.9611 10.8225H3.15204L2.29518 11.6794V16.8512L3.15204 17.7081H9.9611L17.6117 23.8285Z" fill="black" />
        </g>
        <path d="M19.2183 24.5936H16.816L9.16541 18.4732H2.20338L0 16.2698V9.20063L2.20338 6.99725H9.16541L16.816 0.87677H19.2183L20.6566 2.31509V23.1553L19.2183 24.5936ZM17.6117 22.2985H18.2696L18.3614 22.2067V3.26375L18.2696 3.17195H17.6117L9.9611 9.29243H3.15204L2.29518 10.1493V15.3211L3.15204 16.178H9.9611L17.6117 22.2985Z" fill="white" />
        <g opacity="0.2">
          <path opacity="0.2" d="M24.9103 20.4316L23.2883 18.8097C25.1092 16.9889 25.1092 11.5416 23.2883 9.7208L24.9103 8.09888C27.6798 10.8684 27.6798 17.6621 24.9103 20.4316Z" fill="black" />
        </g>
        <g opacity="0.2">
          <path opacity="0.2" d="M27.9094 25.0833L26.4099 23.3389C28.6745 21.3956 30.021 17.9988 30.021 14.2653C30.021 10.5165 28.6745 7.13494 26.4099 5.19169L27.9094 3.44733C30.6789 5.81901 32.3315 9.85854 32.3315 14.2653C32.3315 18.672 30.6789 22.7116 27.9094 25.0833Z" fill="black" />
        </g>
        <path d="M24.9103 18.9016L23.2883 17.2796C25.1092 15.4588 25.1092 10.0116 23.2883 8.19071L24.9103 6.56879C27.6798 9.3383 27.6798 16.132 24.9103 18.9016Z" fill="white" />
        <path d="M27.9094 23.5532L26.4099 21.8088C28.6745 19.8655 30.021 16.4687 30.021 12.7352C30.021 8.9864 28.6745 5.60485 26.4099 3.6616L27.9094 1.91724C30.6789 4.28892 32.3315 8.32845 32.3315 12.7352C32.3315 17.1419 30.6789 21.1815 27.9094 23.5532Z" fill="white" />
      </svg>
    </div>
  );
}
