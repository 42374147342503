import React, { useEffect, useRef, useState } from 'react';
import { Range } from 'react-range';

import Play from '../svg/play';
import DiskIcon from '../svg/disk';
import Waves from '../svg/waves';
import VolumeIcon from '../svg/volume';
import Pause from '../svg/pause';

import './index.css';

interface PlayerPropsType {
  className?: string;
}

const AUDIO_URL = `https://stream.vestaradio.com/AlgOneRadio`;
const STEP = 1;
const MIN = 0;
const MAX = 10;

export default function Player({ className = '' }: PlayerPropsType) {
  const audioRef = useRef<HTMLAudioElement>(null);
  const [volume, setVolume] = useState([MAX]);
  const [titleData, setTitleData] = useState({
    idtitre: 'chargement',
    title: 'Chargement..',
    cover: null,
    play: false,
    startDuration: 0,
  });
  const activeTrackStyle = { width: `calc(100% * ${volume[0]}/${MAX})` };
  let isMounted = true;
  let timerID: any;
  /**
   * set volume using range
   * @param volume list og volumns
   */
  const onChangeVolume = (volume: number[]) => {
    setVolume(volume);
    if (audioRef.current) {
      audioRef.current.volume = volume[0] / MAX;
    }
  }
  /**
   * load current program data
   */
  const loadTitleData = async () => {
    try {
      const response = await fetch('/algone-data/');
      const data = await response.json();

      if (data.status === 'success' && data?.data.idtitre !== titleData.idtitre) {
        isMounted && setTitleData({
          ...titleData,
          title: data.data?.titre || data.data?.artist,
          cover: data.data?.pochette,
          idtitre: data.data?.pochette,
          startDuration: titleData.startDuration || 0, // was titleData.startDuration + 10
        });
      }

    } catch (error) { }
  }
  /**
   * play/pause live audio
   */
  const onPlayPauseAudio = async () => {
    const audioPlayer = audioRef?.current;

    try {
      if (titleData.play === false) {
        setTitleData({ ...titleData, play: true });
        (audioPlayer as any).src = `${AUDIO_URL}?time=${Date.now()}`;
        await audioPlayer?.play();
      } else {
        setTitleData({ ...titleData, play: false });
        audioPlayer?.pause();
      }
    } catch (error) { }
  }

  useEffect(() => {
    loadTitleData();
    timerID = setInterval(loadTitleData, 10 * 1000);
    return () => {
      isMounted = false;
      clearInterval(timerID);
    }
  }, [titleData.startDuration, titleData.play]);

  return (
    <div className={`player__container ${className}`}>
      <div className="d-flex h-100">
        <div className="player__cover d-flex justify-content-center align-items-center overflow-hidden">
          {
            titleData?.cover === null
              ? <DiskIcon />
              : <img className={`img-fluid`} src={titleData.cover || ''} alt={titleData.title} />
          }
        </div>
        <div className="player__controls bg-black d-flex align-items-center">
          <button onClick={onPlayPauseAudio} className="btn border-0 mr-1 mr-md-4 player__play-pause d-flex justify-content-center align-items-center">
            {
              titleData.play
                ? <Pause />
                : <Play height={37} width={37} />
            }
          </button>
          <div className="flex_1 d-flex flex-column justify-content-arround align-items-center">
            <div className="d-flex align-items-center mb-0 mb-md-2">
              <h2 className='player__label m-0 text-truncate font-weight-normal fsz-22 text-white mr-3'>
                <span className="left-to-right__animation">
                  {titleData.title}
                </span>
              </h2>
              <Waves animated={titleData.play} />
            </div>
            <div className="player__volume d-flex w-100 align-items-center mb-2 mb-md-0">
              <VolumeIcon className='mr-2 mr-sm-0' />
              <Range
                values={volume}
                step={STEP}
                min={MIN}
                max={MAX}
                onChange={onChangeVolume}
                renderTrack={({ props, children }) => (
                  <div
                    onMouseDown={props.onMouseDown}
                    onTouchStart={props.onTouchStart}
                    className='w-100 d-flex justify-content-start justify-content-sm-end'
                    style={props.style}
                  >
                    <div
                      ref={props.ref}
                      className='player__volume-range w-100 position-relative'
                    >
                      <div className="player__volume-active-track" style={activeTrackStyle} />
                      {children}
                    </div>
                  </div>
                )}
                renderThumb={({ props }) => (
                  <div {...props} className='player__volume-indicator' />
                )}
              />
            </div>
          </div>
          <audio
            src={AUDIO_URL}
            controls={false}
            ref={audioRef} />
        </div>
      </div>
    </div>
  )
}
